import { Tag, Intent, ControlGroup, InputGroup, Button } from "@blueprintjs/core";
import React from "react"

class EmailListField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pending_email : "",
    }

    this.removeEmail = this.removeEmail.bind(this);
    this.addEmail = this.addEmail.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
  }

  removeEmail(index) {
    let self = this;
    let emails = self.props.value.split(",");
    emails.splice(index, 1);
    console.log("Remove End", index, emails.join(","))
    if(self.props.onChange) { self.props.onChange(emails.join(","), self.props.fieldKey)}
  }

  addEmail(evt) {
    let self = this;
    if(self.state.pending_email.length < 5) { return; }
    let addressComponents = self.state.pending_email.split("@");
    if(addressComponents.length !== 2) { return; }
    let user = addressComponents[0];
    let domain = addressComponents[1];
    let domainParts = domain.split(".");
    if(domainParts.length < 2) { return; }
    let emails = (self.props.value == null ? [] : self.props.value.split(","));
    emails = emails.filter((email) => {
      return email.length > 0;
    })
    emails.push(self.state.pending_email);
    if(self.props.onChange) { self.props.onChange(emails.join(","), self.props.fieldKey)}
    self.setState({ pending_email : "" });
  }

  checkEnter(e) {
    if (e.keyCode === 13) {
      this.addEmail();
    }
  }


  emailTags(value) {
    if(value == null || value.length == 0) { return [] }
    let self = this;
    let emailList = value.split(",");

    // Shamelessly Lifted From https://www.emailregex.com
    let emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(emailList.length == 0) {
      return [];
    }

    let tagElements = emailList.map((email, index, sourceArray) => {
      if(self.props.readOnly) {
        return (<Tag style={{ marginRight: 5 }} key={"email_tag_"+(index-1)+"_"+index}  intent={(emailValidator.test(email) ? "none" : "danger")}> {email} </Tag>);
      }
      return (<Tag style={{ marginRight: 5 }} key={"email_tag_"+(index-1)+"_"+index} removable="true" intent={(emailValidator.test(email) ? "none" : "danger")} onRemove={self.removeEmail.bind(null, index)}> {email} </Tag>);
    })


    return tagElements;
  }

  render () {
    let self = this;
    let emailIntent = Intent.NONE;
    

    let isValid = (() => {
      if(self.state.pending_email.length < 5 ) { return false; }
      let addressComponents = self.state.pending_email.split("@");
      if(addressComponents.length !== 2) { return false; }
      let user = addressComponents[0];
      let domain = addressComponents[1];
      let domainParts = domain.split(".");
      if(domainParts.length < 2) { return false; }
      return true;
    })();

    if(!isValid) { emailIntent = Intent.DANGER; }
    let tagList = self.emailTags(self.props.value)
    return ( <div style={{ paddingLeft: 5 }}>
      <div style={{ fontWeight : "bold" }}> {self.props.title} </div>
      <div>
        <ControlGroup style={{ paddingTop: 5, paddingBottom: 5 }}>
          {(tagList.length == 0 ? <Tag intent="danger">No Emails</Tag> : tagList)}
          
          {( self.props.readOnly === true ? <span></span> : <InputGroup intent={emailIntent} value={self.state.pending_email} onKeyDown={this.checkEnter} onChange={(evt) => { self.setState({ pending_email : evt.target.value }) }} placeholder="Email"/> )} 
          {( self.props.readOnly === true ? <span></span> : <Button onClick={self.addEmail}> Add </Button> )} 
        </ControlGroup>
      </div>
    </div> )
  }

}

export default EmailListField
